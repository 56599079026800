// stylelint-disable selector-no-qualifying-type
//
// TeamDynamix Overrides
// TeamDynamix-specific overrides for Bootstrap styles
// --------------------------------------------------
@media print {
  // Don't show links at all in print views
  a[href]:after {
    content: "";
  }
}
